import {
  SEARCH_MOVIE,
  FETCH_MOVIES,
  FETCH_MOVIE,
  FETCH_COMMENTS,
  LOADING,
  ERROR_API,
  LOADING_MONO_GOLF,
  LOADING_COMMENTS,
  
  DISPLAY_MAP_POINT,
  DISPLAY_MONO_GOLF,
  FILTER_SELECTION,
  MOVIES_SELECTION
} from '../actions/types';

export const initialState = {
  filtersSelection : {difficulty:{Accessible: true, Challengeant: true, Exigeant: true},
                      price:[5, 100],
                      chaine:{Tous: true, Bluegreen: true, Ugolf: true, LeClub_Golf: true, Golfy: true},
                      competition: false,
                      numberHoles:{NineHoles: true, EighteenHoles: true, Autres: true}},
  displayMonoGolf : {idMovie:'', stateOpen:false},
  displayMapPoint :'',
  moviesSelection : [],
  searchFieldValues: {'txt':"alitest", 'selectedNumberHoles':'etst' },
  movies: [],
  errorApi: '', 
  movie: [],
  comments: [],
  loading: false,
  loadingMonoGolf: false,
  loadingComments: false

};

export default function(state = initialState, action) {
  switch (action.type) {
    case FILTER_SELECTION:
      return {
        ...state,
        filtersSelection: action.payload,
        loading: false
      };
    case MOVIES_SELECTION:
      return {
        ...state,
        moviesSelection: action.payload,
        loading: false
      };
    case DISPLAY_MONO_GOLF:
      return {
        ...state,
        displayMonoGolf: action.payload,
        loading: false
      };
    case DISPLAY_MAP_POINT:
      return {
        ...state,
        displayMapPoint: action.payload,
        loading: false
      };
    case SEARCH_MOVIE:
      return {
        ...state,
        searchFieldValues: action.payload,
        loading: false
      };
    case ERROR_API:
      return {
        ...state,
        errorApi: action.payload
      };
    case FETCH_MOVIES:
      return {
        ...state,
        movies: action.payload,
        loading: false,
        redirection: false,
        errorApi:''
      };
    case FETCH_MOVIE:
      return {
        ...state,
        movie: action.payload,
        loadingMonoGolf: false
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        comments: action.payload,
        loadingComments : false
      };
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case LOADING_MONO_GOLF:
      return {
        ...state,
        loadingMonoGolf: true
      };
    case LOADING_COMMENTS:
      return {
        ...state,
        loadingComments: true
      };
    default:
      return state;
  }
}
