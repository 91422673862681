import React, { Component, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route } from 'react-router-dom';

// import './App.css';
import './App_clean.css';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Skeleton from '@material-ui/lab/Skeleton';

// <Route path="/admin" component={Admin} />
// <Route path="/login" component={SignIn} />
// <Route path="/signup" component={SignUp} />

/*
import Landing from './containers/Landing';

import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';

import Result from './containers/Result';
import MonoGolf from './containers/MonoGolf';

import NavBar from './components/layout/Navbar';
import FooterNav from './components/layout/Footer';

import Blog from './components/blog/BlogLanding';
import BlogPageContainer from './components/blog/BlogPageContainer';

import Contact from './components/contact/ContactLanding';

*/

import store from './store/store';

const NavBar = lazy(() => import('./components/layout/Navbar'));
const Landing = lazy(() => import('./containers/Landing'));
const Result = lazy(() => import('./containers/Result'));
const MonoGolf = lazy(() => import('./containers/MonoGolf'));
const Blog = lazy(() => import('./components/blog/BlogLanding'));
const BlogPageContainer = lazy(() => import('./components/blog/BlogPageContainer'));
const Contact = lazy(() => import('./components/contact/ContactLanding'));
const FooterNav = lazy(() => import('./components/layout/Footer'));
const MentionsLegales = lazy(() => import('./components/layout/MentionsLegales'));

/*
import GolfCardImprove from './components/result/card/GolfCardImprove';

function Admin() {
  const movie2 = {
    competition:3,
    bab_title: "Golf Blue Green Baden - Baden",
    chaine: "Bluegreen",
    departement: "Morbihan",
    ffg_parcours: "18 trous et plus",
    ffg_title: "Golf Bluegreen Baden",
    full_adress: "Kernic, Baden, Morbihan, France",
    garmin_title: "Golf De Baden",
    imdbID: 525,
    latitude: 47.6227435,
    longitude: -2.9376252,
    materiel: "location_materiel | voiturette",
    parcours: "18 trous | 6 trous",
    phone: "02 97 57 18 96",
    point: "(-2.9376252,47.6227435)",
    prestation: "seminaires | restaurant",
    region: "Bretagne",
    short_adress: "Baden, Morbihan, France",
    slp_parcours: "18 trous | 6 trous",
    slp_title: "Golf de Baden",
    time: 856.6,
    time_formated: "14 minutes",
    title: "Golf de Baden",
    type: "Public",
    website: "https://www.bluegreen.fr/baden/",
  };
  return <div>
    <GolfCardImprove movie={movie2}/>
  </div>;
}*/
/*
function Admin() {
 
  return <div>
    <GolfCardImprove movie={movie2}/>
  </div>;
}*/

// function MyErrorBoundary() { return <div>error 404</div>; }

/*<div>
  <Skeleton className='m-0 p-0' variant="text" height='10vh' style={{transform: 'scale(1, 0.9)'}}/>
  <Skeleton className='m-0 p-0' variant="text" height='100vh' style={{transform: 'scale(1, 0.9)'}}/>
</div>*/
//  style={{"min-height":"100%"}}

class ErrorBoundary extends React.Component {
  // throw new Error('I crashed!');
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {    
    this.setState({ hasError: true });
    //logErrorToMyService(error, info);  
  }
  render() {
    if (this.state.hasError) {    
      return (
      <div className="text-center">
        <div className="empty-image my-4" alt="sorry :/"></div >
        <div className="text-center">
          Oups une erreur s'est produite ... <br/> Notre développeur s'en occupe dans les plus brefs délais ...
        </div >
      </div>
      );
    } 
    return this.props.children;
  }
}

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
      <Provider store={store}>
        <Router>

            <Suspense fallback={
              <div className='d-flex' style={{height:'100vh', alignItems: 'center', justifyContent: 'center'}}> <CircularProgress/> </div>
            }>
              <div className="home-container">
                <NavBar/>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/result" component={Result} />
                  <Route exact path="/golf/:id/:title" component={MonoGolf} />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/article-blog/:title_blog" component={BlogPageContainer} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/mentions-legales" component={MentionsLegales} />
                <FooterNav/>
              </div>
            </Suspense>
        
        </Router>
      </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
//style={{height:"100vh"}}
