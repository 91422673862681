export const SEARCH_MOVIE = 'SEARCH_MOVIE';
export const FETCH_MOVIES = 'FETCH_MOVIES';
export const FETCH_MOVIE = 'FETCH_MOVIE';
export const LOADING = 'LOADING';
export const ERROR_API = 'ERROR_API';
export const LOADING_MONO_GOLF = 'LOADING_MONO_GOLF';
export const DISPLAY_MAP_POINT = 'DISPLAY_MAP_POINT';
export const DISPLAY_MONO_GOLF = 'DISPLAY_MONO_GOLF';
export const FILTER_SELECTION = 'FILTER_SELECTION';
export const MOVIES_SELECTION = 'MOVIES_SELECTION';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const LOADING_COMMENTS = 'LOADING_COMMENTS';